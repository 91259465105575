import React from "react";
import Link from "antd/es/typography/Link";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import QPageLayout from "components/layout/QPageLayout";
import NoRecurringTransactions from "components/tenant/recurring_transactions/NoRecurringTransactions";
import { PAGE_TITLES } from "constants/localization/global";
import { ACTIONS } from "constants/localization/recurring_transactions";

const RecurringTransactions = () => {
  const { t } = useTranslation();
  const pageTitle = t(PAGE_TITLES.recurring_transactions);

  return (
    <QPageLayout
      title={pageTitle}
      breadCrumbData={[
        { title: <Link href="#">{t(PAGE_TITLES.accounting)}</Link> },
        { title: pageTitle },
      ]}
      actionBtns={
        <Button type="primary" href="recurring_transactions/new">
          <PlusOutlined /> {t(ACTIONS.create)}
        </Button>
      }
    >
      <NoRecurringTransactions />
    </QPageLayout>
  );
};

export default RecurringTransactions;

import React, { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Select,
  Space,
  theme,
  TreeSelect,
} from "antd";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";
import { useMutation, useQuery } from "react-query";
import {
  getCategories,
  getBudgetsLastReference,
  postBudget,
  getAssetClassifications,
  updateBudget,
} from "api/budgets";
import AttachmentField from "./AttachmentField";
import { AxiosError } from "axios";
import i18next from "i18next";
import { filterTreeNode, transformData } from "helpers/budgets";
import BudgetDimensions from "./BudgetDimensions";
import { Budget, Category } from "types/budget";
const { useToken } = theme;



const { Option } = Select;

const ItemBudgeting = ({
  type = "new",
  budget = null,
  id = null,
  hasValues = false,
}) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { t } = useTranslation();
  const currentYear = dayjs();
  const { token } = useToken();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const disabledDate = (current: Dayjs | null): boolean => {
    return current ? current.year() < new Date().getFullYear() : false;
  };

  const alphanumericPattern = /^[a-zA-Z0-9-_]*\d$/;

  const createBudget = useMutation({
    mutationFn: (data) => postBudget(data, id),
    onSuccess(data, variables, context) {
      window.location.href = "/tenant/budgets";
    },
    onError: (error: AxiosError<any>) => {
      if (error.response.data.code || error.response.data.errors) {
        message.error(
          t("budgets.accounts_budgeting.fields.reference.validation3")
        );
      }
    },
  });

  const editBudget = useMutation({
    mutationFn: (data) => updateBudget(data, id),
    onSuccess(data, variables, context) {
      window.location.href = "/tenant/budgets";
    },
    onError: (error: AxiosError<any>) => {
      if (error.response.data.code || error.response.data.errors) {
        message.error(
          t("budgets.accounts_budgeting.fields.reference.validation3")
        );
      }
    },
  });

  const lastReference = useQuery({
    queryKey: "budgetsLastReference",
    queryFn: () => getBudgetsLastReference(),
  }).data?.next_code;

  const categories = useQuery({
    queryKey: "categories",
    queryFn: () => getCategories(),
  }).data;

  const assetClassifications = useQuery({
    queryKey: "asset_classifications",
    queryFn: () => getAssetClassifications(),
  }).data;

  const transformCategories = transformData<Category>(categories, "name");

  const handleSubmit = (formData: FormData) => {
    if (type == "new") {
      createBudget.mutate(formData as any);
    } else {
      editBudget.mutate(formData as any);
    }
  };
  
  const onFinish = (values: Budget) => {
    const formData = new FormData();
  
    if (type === "new") {
      formData.append("budget[code]", values.code);
      values.description && formData.append("budget[description]", values.description);
      formData.append("budget[name_ar]", values.name_ar);
      formData.append("budget[name_en]", values.name_en);
      !hasValues && formData.append("budget[fiscal_year]", values.fiscal_year.add(1, "month").toISOString());
      values.kind && formData.append("budget[kind]", values.kind);
      !hasValues && formData.append("budget[transaction_type]", values.transaction_type);
      !hasValues && formData.append("budget[base]", values.base);
      
      values.category_ids?.forEach((el) => {
        !hasValues && formData.append(`budget[category_ids][]`, el);
      });
      
      values.asset_classification_ids?.forEach((el) => {
        !hasValues && formData.append(`budget[asset_classification_ids][]`, el);
      });
  
      const attachment = values.attachment?.map((el) => el.originFileObj);
      
      attachment?.forEach((file) => {
        formData.append(`budget[attachments][]`, file);
      });
  
      values.dimensions?.forEach((el) => {
        formData.append(`budget[dimension_ids][]`, el.selectedDimension.value);
      });
  
      handleSubmit(formData);
  
    } else {
    let hasData = false;
      const updatedFormData = new FormData();
    for (const key in values) {
      if (
        values.hasOwnProperty(key) &&
        JSON.stringify(values[key]) !== JSON.stringify(initialValues[key])
      ) {
        if (key === "fiscal_year") {
          const fiscalYearValue = values[key].add(1, "month").toISOString();
          updatedFormData.append(`budget[${key}]`, fiscalYearValue);
        } else if (key === "category_ids") {
          values[key].forEach((el) => {
            updatedFormData.append(`budget[category_ids][]`, el);            
          });
        } else if (key === "asset_classification_ids") {
          values[key].forEach((el) => {
            updatedFormData.append(`budget[asset_classification_ids][]`, el);            
          });
        } else if (key === "dimensions") {
          values[key].forEach((dimension) => {
            const dimensionId = dimension.selectedDimension?.value; 
            updatedFormData.append(`budget[dimension_ids][]`, dimensionId);
          });
        } else if (key === "attachment") {
          const attachment = values.attachment?.map((el) => el.originFileObj);
          attachment?.forEach((file) => {
            updatedFormData.append("budget[attachments][]", file);
          });
        } else {
          updatedFormData.append(`budget[${key}]`, values[key]);
        }
      }
    }    
    updatedFormData.forEach((value, key) => {
      hasData = true;
    });   
    if (hasData) {
        handleSubmit(updatedFormData as any);   
    } 
    updatedFormData.forEach((value, key) => {
    });
    }
  };
  

  const [selectedItemCategory, setSelectedItemCategory] = useState(
    budget?.kind || ""
  );
  const [selectedTransaction, setSelectedTransaction] = useState(
    budget?.transaction_type || ""
  );

  useEffect(() => {
    if (lastReference) {
      const incomingData = type === "edit" ? budget : { code: lastReference };
      form.setFieldsValue(incomingData);
      setInitialValues(incomingData);
    }
  }, [lastReference, form, budget]);

  return (
    <Form
      name="itemBudgeting"
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{
        maxWidth: 600,
        fontSize: token.fontSizeSM,
        fontWeight: "normal",
      }}
      onFinish={onFinish}
      initialValues={{
        fiscal_year: currentYear,
      }}
      autoComplete="off"
      onValuesChange={() => setIsFormChanged(true)}
    >
      <Form.Item
        label={t("budgets.accounts_budgeting.fields.reference.name")}
        name="code"
        rules={[
          {
            required: true,
            message: t(
              "budgets.accounts_budgeting.fields.reference.validation1"
            ),
          },
          {
            max: 25,
            message: t(
              "budgets.accounts_budgeting.fields.reference.validation4"
            ),
          },
          {
            pattern: alphanumericPattern,
            message: t(
              "budgets.accounts_budgeting.fields.reference.validation2"
            ),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("budgets.accounts_budgeting.fields.description.name")}
        name="description"
        rules={[
          {
            max: 100,
            message: t(
              "budgets.accounts_budgeting.fields.description.validation"
            ),
          },
        ]}
      >
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item
        label={t("budgets.accounts_budgeting.fields.arabic_name.name")}
        name="name_ar"
        rules={[
          {
            required: true,
            message: t(
              "budgets.accounts_budgeting.fields.arabic_name.validation"
            ),
          },
          {
            max: 25,
            message: t(
              "budgets.accounts_budgeting.fields.arabic_name.validation1"
            ),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("budgets.accounts_budgeting.fields.english_name.name")}
        name="name_en"
        rules={[
          {
            required: true,
            message: t(
              "budgets.accounts_budgeting.fields.english_name.validation"
            ),
          },
          {
            max: 25,
            message: t(
              "budgets.accounts_budgeting.fields.english_name.validation1"
            ),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("budgets.accounts_budgeting.fields.fiscal_year.name")}
        name="fiscal_year"
        rules={[
          {
            required: true,
            message: t(
              "budgets.accounts_budgeting.fields.fiscal_year.validation"
            ),
          },
        ]}
      >
        <DatePicker
          disabled={hasValues}
          inputReadOnly
          picker="year"
          disabledDate={disabledDate}
        />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: t("budgets.item_budgeting.fields.bases.validation"),
          },
        ]}
        label={t("budgets.item_budgeting.fields.bases.name")}
        name="base"
      >
        <Select disabled={hasValues}>
          <Option value="quantity">
            {t("budgets.item_budgeting.fields.bases.quantity_base")}
          </Option>
          <Option value="amount">
            {t("budgets.item_budgeting.fields.bases.amount_base")}
          </Option>
        </Select>
      </Form.Item>
      <Form.Item
        label={t("budgets.item_budgeting.fields.transaction_types.name")}
        name="transaction_type"
        rules={[
          {
            required: true,
            message: t(
              "budgets.item_budgeting.fields.transaction_types.validation"
            ),
          },
        ]}
      >
        <Select
          disabled={type === "edit"}
          onChange={(value) => {
            setSelectedTransaction(value);
            form.resetFields([
              "category_ids",
              "kind",
              "asset_classification_ids",
            ]);
            setSelectedItemCategory("");
          }}
        >
          <Option value="sale">
            {t("budgets.item_budgeting.fields.transaction_types.sales_budget")}
          </Option>
          <Option value="purchase">
            {t(
              "budgets.item_budgeting.fields.transaction_types.purchase_budget"
            )}
          </Option>
        </Select>
      </Form.Item>
      {selectedTransaction != "sale" && (
        <Form.Item
          label={t("budgets.item_budgeting.fields.item_category.name")}
          name="kind"
          rules={[
            {
              required: true,
              message: t(
                "budgets.item_budgeting.fields.item_category.validation"
              ),
            },
          ]}
        >
          <Select
            disabled={type === "edit"}
            onChange={(value) => {
              setSelectedItemCategory(value);
              form.resetFields(["category_ids"]);
              form.resetFields(["asset_classification_ids"]);
            }}
          >
            <Option value="products_budget">
              {t(
                "budgets.item_budgeting.fields.item_category.product_categories"
              )}
            </Option>
            <Option value="assets_budget">
              {t(
                "budgets.item_budgeting.fields.item_category.asset_classification"
              )}
            </Option>
          </Select>
        </Form.Item>
      )}
      <Form.Item
        label={t("budgets.item_budgeting.fields.product_categories.name")}
        name="category_ids"
        rules={[
          {
            required:
              selectedItemCategory == "products_budget" ||
              selectedTransaction == "sale",
            message: t(
              "budgets.item_budgeting.fields.product_categories.validation"
            ),
          },
        ]}
      >
        <TreeSelect
          disabled={
            (selectedItemCategory != "products_budget" &&
              selectedTransaction != "sale") ||
            hasValues
          }
          treeData={transformCategories}
          treeCheckable
          showCheckedStrategy={TreeSelect.SHOW_PARENT}
          style={{ width: "100%" }}
          filterTreeNode={filterTreeNode}
        />
      </Form.Item>
      {selectedTransaction != "sale" && (
        <Form.Item
          label={t("budgets.item_budgeting.fields.asset_classification.name")}
          name="asset_classification_ids"
          rules={[
            {
              required: selectedItemCategory == "assets_budget",
              message: t(
                "budgets.item_budgeting.fields.asset_classification.validation"
              ),
            },
          ]}
        >
          <Select
            disabled={selectedItemCategory != "assets_budget" || hasValues}
            mode="multiple"
            filterOption={(input, option) =>
              (option?.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {assetClassifications?.map((el) => {
              return (
                <Option value={el.id} key={el.id}>
                  {i18next.language == "en" ? el.english_name : el.arabic_name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      )}
      <AttachmentField />
      <BudgetDimensions type={type} budget={budget} hasValues={hasValues} />
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Space>
          <Button type="primary" 
          htmlType="submit"
          disabled={!isFormChanged}>
            {type == "edit"
              ? t("budgets.edit.update")
              : t("forms.buttons.save")}
          </Button>
          <Button htmlType="button" onClick={() => window.go_back()}>
            {t("forms.buttons.cancel")}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ItemBudgeting;

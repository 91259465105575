import { TransformedData, TreeNode } from "types/budget";
 
export const transformData = <T extends { id: number; children?: T[] }>(
  data: T[],
  labelKey: keyof T
): TransformedData[] => {
  return data?.map((item) => {
    const transformedItem: TransformedData = {
      value: item.id,
      label: String(item[labelKey]),
      title: String(item[labelKey]),
    };
    if (item.children && item.children.length > 0) {
      transformedItem.children = transformData(item.children, labelKey);
    }
    return transformedItem;
  });
};

export const filterTreeNode = (inputValue: string, treeNode: TreeNode) => {
  return (
    treeNode.title.toLowerCase().includes(inputValue.toLowerCase()) ||
    treeNode.value == inputValue
  );
};

export const getBudgetIdFromUrl = () => {
  const urlParts = window.location.pathname.split("/");
  return urlParts[urlParts.length - 1];
};

export const resetDateToZero = (formData, cycle, recordId = null) => {
  const resetFormData = {};

  if (recordId) {
    Object.keys(formData).forEach((key) => {
      resetFormData[key] = {
        ...formData[key],
        budget_amount: Number(key.trim()) === recordId ? 0 : formData[key].budget_amount,
        ...Object.fromEntries(
          Array.from({ length: cycle.no }, (_, index) => [
            index + 1,
            Number(key.trim()) === recordId ? 0 : formData[key][index + 1],
          ])
        ),
      };
    });
  } else {
    Object.keys(formData).forEach((key) => {
      resetFormData[key] = {
        ...formData[key],
        budget_amount: 0,
        ...Object.fromEntries(
          Array.from({ length: cycle.no }, (_, index) => [index + 1, 0])
        ),
      };
    });
  }

  return resetFormData;
};

export const updateParent = (formData, parentId, cycle) => {
  if (!parentId) return formData;

  let parentRecord = formData[parentId];
  if (parentRecord) {
    parentRecord.budget_amount = parentRecord.children.reduce((sum: number, childId: string | number) => {
      const childRecord = formData[childId];
      return sum + (parseFloat(childRecord?.budget_amount) || 0);
    }, 0);

    for (let i = 1; i <= cycle.no; i++) {
      parentRecord[i] = parentRecord.children.reduce((sum: number, childId: string | number) => {
        const childRecord = formData[childId];
        return sum + (parseFloat(childRecord?.[i]) || 0);
      }, 0);
    }
    formData[parentId] = parentRecord;
    if (parentRecord.parentId) {
      return updateParent(formData, parentRecord.parentId, cycle);
    }
  }

  return formData;
};

export const calculateBudgetAmount = (values) => {
  return values.reduce((sum, val) => {
    const numericVal = parseFloat(val) || 0;
    return sum + numericVal;
  }, 0);
}; 

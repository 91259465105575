import React from "react";
import { useTranslation } from "react-i18next";
import RecurringTransactionsIcon from "assets/icons/Recurring-Transactions.svg";
import QOnBoardingIndex from "components/layout/QOnBoardingIndex";
import { ACTIONS, ON_BOARDING } from "constants/localization/recurring_transactions";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const NoRecurringTransactions = () => {
  const { t } = useTranslation();

  return (
    <QOnBoardingIndex
      icon={<RecurringTransactionsIcon />}
      title={t(ON_BOARDING.title)}
      subtitle={t(ON_BOARDING.desc)}
      extra={
        <Button
          type="primary"
          href="recurring_transactions/new"
          style={{ height: "auto", whiteSpace: "pre-wrap" }}
        >
          <PlusOutlined /> {t(ACTIONS.create)}
        </Button>
      }
    />
  );
};

export default NoRecurringTransactions;
